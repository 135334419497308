
.filter-div-acordion-item{
    /* padding-top: var(--padding-md);
    padding-bottom: var(--padding-md); */
  }
  .filter-div2 {
    align-self: stretch;
    border-top: 0 solid var(--base-neutral-100);
    border-right: 0 solid var(--base-neutral-100);
    border-bottom: 1px solid var(--base-neutral-100);
    border-left: 0 solid var(--base-neutral-100);
    padding: var(--padding-lg);
  }
  .acordion-panel{
    font-weight: 600;
  }
  .acordion-button{
    font-weight: 600;
  }
  .accordion-item-p{
    color:#666666;
    margin-top:var(--padding-sm) ;
    font-weight: 100;
  }
  .checkbox-top{
    margin-top:var(--padding-md) ;
  }
  .accordion-item-p-bold{
    color:#666666;
    margin-top:var(--padding-sm) ;
    margin-bottom:var(--padding-sm) ;
    font-weight: bold;
  }


  .rounded-full{
    border-radius: 624.9375rem;
    display: inline-block !important;
    width: 19px !important;
    height: 19px !important;
    border: 1px solid #DBDBDB !important;
    margin-right: 0.5rem;
    vertical-align: bottom;
    margin-bottom: 3px;
  }
.css-8c8uhv{
  padding: inherit;
  padding-left: 0;
}
  .color-checkbox{
    vertical-align: top!important;
  }

  .checkbox-color-div{
    width: 100%;
    margin-top:var(--padding-sm) ;
  }