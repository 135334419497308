body {
  margin: 0;
  line-height: normal;
}
.divider-icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
}
.button {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.secondary-button {
  border-radius: var(--br-md);
  padding: var(--padding-sm) var(--padding-md);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.primary-button,
.secondary-button {
  display: flex;
  flex-direction: row;
}
.primary-button {
  border-radius: var(--br-md);
  background-color: var(--base-red-700);
  padding: var(--padding-sm) var(--padding-md);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--base-white);
}
.buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: calc(100% - 32px);
  top: 30px;
  right: 16px;
  left: 16px;
  align-items: flex-start;
  justify-content: space-between;
}
.controls {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 16px;
  left: 0;
  height: 57px;
}
.filtermenu,
.placeholder {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
}
.placeholder {
  height: calc(100% - 121px);
  width: calc(100% - 48px);
  top: 24px;
  right: 24px;
  bottom: 97px;
  left: 24px;
  border-radius: 8px;
  background-color: var(--base-neutral-50);
  border: 2px dashed var(--base-neutral-100);
  display: none;
  flex-direction: column;
  padding: 24px;
  align-items: center;
  justify-content: center;
  color: var(--base-neutral-300);
}
.filtermenu {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  background-color: var(--base-white);
  box-shadow: 0 10px 37px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--base-neutral-75);
  color: var(--base-neutral-700);
}
.iconchevron-down {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.distance,
.select {
  position: absolute;
  left: 24px;
  display: flex;
  align-items: center;
  width: 272px;
}
.select {
  top: 136px;
  border-radius: var(--br-md);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-200);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-md);
  justify-content: center;
  gap: 8px;
}
.distance {
  top: 108px;
  font-size: var(--base-text-small-size);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-black);
}
.icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.input-value {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
}
.city-or-zip,
.input {
  position: absolute;
  left: 24px;
  display: flex;
  align-items: center;
  width: 272px;
}
.input {
  top: 52px;
  border-radius: var(--br-md);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-200);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-md);
  justify-content: center;
  gap: 4px;
}
.city-or-zip {
  top: 24px;
  font-size: var(--base-text-small-size);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-black);
}
.cl1-search-filter-locatio {
  position: relative;
  width: 100%;
  height: 50;
  text-align: left;
  font-size: var(--semantic-button-default-size);
  color: var(--base-neutral-900);
  font-family: var(--semantic-button-default);
}
