
  .carGraph {

    width: 100%;
    font-family: sans-serif;
    font-style: oblique;
    color: #dc143c;
    text-align: center;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }


  .content{
    display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px 8px 0px 0px;
gap: 12px;

width: 237px;
height: 116px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 1;
z-index: 1;
  }
.carImage{
  /* Image */


box-sizing: border-box;

width: 100px;
height: 100px;


/* Base/neutral/100 */

border: 1px solid #DBDBDB;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
z-index: 0;
}
  @media screen and (max-width:800px) {
    .carGraph {
      display: none;
    }
  }

@media screen and (max-width:1260px)  {
  .carGraph {
    height: 420px;
  }
}

@media screen and (min-width:1261px)  {
  .carGraph {
    height: 350px;
  }
}

.cat-absolute-container {
  z-index: 1;
  
  flex-direction: column;
  flex-basis: 91%;
  justify-content: flex-start;
  align-self: center;
  align-items: center;

 

  display: flex;
}
.tooltipCar {
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border-radius: 1px;
  flex-direction: row;
  flex-basis: 32%;
  justify-content: space-evenly;
  align-items: center;

  padding-top: 8px;
  padding-bottom: 1px;
  padding-left: 4px;
  display: flex;
}

.flex-container-9 {
  height: 80%;
  flex-direction: column;
  flex-basis: 64%;
  justify-content: space-between;
  align-self: flex-start;
  align-items: stretch;
  margin-left: 1px;
  display: flex;
}

.flex-container-10 {
  width: 100%;
  width: 250px;
  color:black;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  margin-left: 1px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}
.flex-container-11 {
  color:black;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.flex-container-12 {
  width: 35%;
  color:black !important; 
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  display: flex;
}
.flex-container-13 {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  display: flex;
}
.tag-instance-3 {
  height: 100%;
  flex-basis: 35%;
  font-size: 12px !important;
  font-weight:bold !important; 
  background-color: var(--base-red-50) !important;
  color:var(--base-red-700) !important;
  margin-left: 16px;
}
.iconmoney {
  color: black;
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.learn-more {
  color: #0978c3;
  margin-top: 3px;
 
 
}
.iconspeedometer {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.icon-buttonTooltip {
  color: gray;
  font-family:var(--semantic-button-default) !important ;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.imageTooltip {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 16px;
  margin-left: 15px;
  margin-right: 15px;
}
