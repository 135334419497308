.content-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  padding: var(--padding-xl) 10% 0;
  box-sizing: border-box;
  gap: var(--gap-2xl);
}

.disclaimer-div {
  width: 34%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  color: var(--base-neutral-100);
}

.logo-cartalk-icon {
  position: relative;
  width: 166.99px;
  height: 100px;
  flex-shrink: 0;
  overflow: hidden;
}

.footer-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--base-neutral-800);
  align-items: flex-start;
  gap: 48px;
  bottom: auto;

  margin-bottom: 0;
}

.quick-links-b {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.div {
  align-self: stretch;
  border-radius: var(--br-xs);
  display: flex;
  padding: var(--padding-3xs) var(--padding-2xs) var(--padding-3xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.div4,
.list-div1 {
  flex-direction: column;
}

.div4 {
  align-self: stretch;
  border-radius: var(--br-xs);
  display: none;
  padding: var(--padding-3xs) var(--padding-2xs) var(--padding-3xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.list-div1 {
  flex: 1;
  gap: var(--gap-md);
}

.list-div,
.list-div1,
.quick-links-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.list-div {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-2xl);
  font-size: var(--semantic-footer-description-size);
}

.quick-links-div {
  width: 33%;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-lg);
  font-size: var(--base-heading-small-size);
}

.contact-us-div {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.contact-us-div-new {
  width: 40%;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.links-div {
  align-items: center;

  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}

.car-talk-is-a-trademark-of-ta {
  align-self: stretch;
  position: relative;
  line-height: 16px;
  font-weight: 600;
  display: inline-block;
}

.disclaimer-div1 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--base-text-xsmall-size);
  color: var(--base-neutral-200);
}

.disclaimer-div1,
.global-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.global-div {
  background-color: var(--base-neutral-900);
  width: 100%;
  overflow: hidden;
  padding: var(--padding-xl) var(--padding-2xl);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-xl);
}

.badge-div,
.popular-topics-b {
  display: flex;
  align-items: center;
}

.popular-topics-b {
  position: relative;
  font-size: var(--base-heading-small-size);
  line-height: 24px;
  width: 100%;
}

.badge-div {
  border-radius: var(--br-lg);
  background-color: var(--base-neutral-700);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  box-sizing: border-box;
  justify-content: center;
}

.row-div {
  flex-direction: row;
  gap: var(--gap-md);
}

.popular-topics-div,
.row-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.popular-topics-div {
  width: 33%;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-lg);
  font-size: var(--semantic-badge-text-size);
}

@media screen and (max-width:800px) {
  .footer-Content { 
    visibility: hidden;
    background-color: var(--base-neutral-800);
  }

  .footer-div {
    visibility: hidden;
    display: initial;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--base-neutral-800);
    align-items: flex-start;
    gap: 0px;
    bottom: 0;
    margin-bottom: 0;
  }

  .disclaimer-div {
    padding-top: 100px;
    width: 100%;
    display: initial;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lg);
    color: var(--base-neutral-100);
  }

  .popular-topics-div {
    display: initial;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    gap: var(--gap-lg);

    padding-left: 5%;
    font-size: var(--semantic-badge-text-size);
  }

  .quick-links-div {

    padding-left: 5%;
    display: initial;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-shrink: 0;
    flex-direction: initial;
    gap: var(--gap-lg);
    font-size: var(--base-heading-small-size);
  }



  .content-div {
    display: initial;
    width: 100%;
    flex-direction: row;
    padding-left: 5%;
    box-sizing: border-box;
    gap: var(--gap-2xl);
    align-items: flex-start;
    justify-content: flex-start;
  }

  .logo-cartalk-icon {
    position: initial;
    width: 5%;
    height: 100px;
    flex-shrink: 0;
    overflow: hidden;
    padding-left: 5%;
  }

  .disclaimer-divText {
    margin-right: 5%;
    padding-left: 5%;
  }

  .quick-links-b {
    width: 100%;

    align-self: stretch;
    position: initial;
    line-height: 24px;
    display: inline-block;
  }

  .quick-links-bT {
    width: 80%;
    padding-top: 30px;
    align-self: stretch;
    position: initial;
    line-height: 24px;
    display: inline-block;
  }

  .list-div {
    padding-left: 5%;
  }

  .popular-topics-b {
    position: initial;
    padding-left: 5%;
    font-size: var(--base-heading-small-size);
    line-height: 24px;
    width: 100%;
  }

  .row-div {
    padding-left: 5%;
    padding-top: 10px;
  }

  .contact-us-div {
    width: 100%;
    position: relative;
    line-height: 24px;
    display: initial;

  }

  .links-div {
    align-items: center;
    display: flex;

  }
}