.dtl-icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.dtl-iconbutton {
  position: absolute;
  top: 8px;
  right: 12px;
  border-radius: var(--br-xs);
  background-color: var(--base-red-700);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.dtl-logo-cartalk-icon {
  position: absolute;
  top: 20px;
  left: 24px;
  width: 95px;
  height: 24px;
}

.dtl-nav-item {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}

.dtl-nav-item-vin {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}

.dtl-headernavbar,
.dtl-navitem {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dtl-navitem {
  border-radius: var(--br-2xs);
  padding: var(--padding-3xs);
  box-sizing: border-box;
  justify-content: center;
}

.dtl-headernavbar {
  position: absolute;
  top: 14px;
  left: calc(50% - 577px);
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.dtl-header,
.dtl-iconexternal-link {
  position: relative;
  flex-shrink: 0;
}

.dtl-header {
  align-self: stretch;
  background-color: var(--base-red-700);
  height: 56px;
  text-align: center;
}

.dtl-iconexternal-link {
  width: 18px;
  height: 18px;
  overflow: hidden;
  display: none;
}

.dtl-link {
  border-radius: var(--br-xs);
  background-color: var(--color-gray-1300);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.dtl-nissan-sentra-sv {
  align-self: stretch;
  position: relative;
  line-height: 50px;
  display: inline-block;
}

.dtl-div,
.dtl-iconlist {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.dtl-div {
  gap: var(--gap-md);
}

.dtl-richmond-va {
  font-weight: 600;
}

.dtl-richmond-va-64-miles-away {
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.dtl-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-md);
}

.dtl-button,
.dtl-button2,
.dtl-buttons {
  display: flex;
  flex-direction: row;
}

.dtl-button {
  cursor: pointer;
  border-radius: var(--br-xs);
  background-color: var(--base-red-50);
  padding: var(--padding-2xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.dtl-button2,
.dtl-buttons {
  gap: var(--gap-md);
}

.dtl-button2 {
  cursor: pointer;
  border-radius: var(--br-xs);
  background-color: var(--base-red-700);
  padding: var(--padding-2xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--base-white);
}

.dtl-buttons {
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: var(--base-text-regular-medium-size);
  color: var(--base-red-700);
}

.dtl-content,
.dtl-details,
.dtl-hero {
  display: flex;
  justify-content: flex-start;
}

.dtl-details {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-lg);
  font-size: var(--semantic-header-navigation-size);
  color: var(--base-neutral-600);
}

.dtl-content,
.dtl-hero {
  flex-direction: column;
  align-items: flex-start;
}

.dtl-content {
  align-self: stretch;
  gap: var(--gap-md);
  font-size: var(--base-heading-2xlarge-size);
  color: var(--base-black);
}

.dtl-hero {
  background-color: var(--base-white);
  width: 100%;
  padding: var(--padding-md) var(--padding-3xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-lg);
  color: var(--base-blue-600);
}

.dtl-div4,
.dtl-overlay {
  position: absolute;
}

.dtl-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--base-blackalpha-400);
}

.dtl-div4 {
  left: 68%;
  border-radius: 0 0 var(--br-md) 0;
  /* background-image: url(.dtl-.dtl-/.dtl-.dtl-/public/4@3x.dtl-png); */
}

.dtl-div4,
.dtl-div5,
.dtl-div6 {
  cursor: pointer;
  top: 504px;
  width: 32% !important;
  height: 256px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.dtl-div5 {
  width: 33%;
  position: absolute;
  left: 34%;
  /* background-image: url(.dtl-.dtl-/.dtl-.dtl-/public/3@3x.dtl-png); */
}

.dtl-div6 {
  width: 33%;
  left: 0;
  border-radius: 0 0 0 var(--br-md);
  /* background-image: url(.dtl-.dtl-/.dtl-.dtl-/public/2@3x.dtl-png); */
}

.dtl-button4,
.dtl-div6,
.dtl-div7 {
  cursor: pointer;
  position: absolute;
}

.dtl-div7 {
  top: 0;
  left: 0;
  border-radius: var(--br-md) var(--br-md) 0 0;
  width: 100%;
  height: 488px;
  overflow: hidden;
  /* background-image: url(.dtl-.dtl-/.dtl-.dtl-/public/1@3x.dtl-png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.dtl-button4 {
  cursor: pointer;
  right: 16px;
  bottom: 16px;
  border-radius: var(--br-xs);
  background-color: var(--base-black);
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.dtl-photogrid {
  position: relative;
  width: 100%;
  height: 760px;
  flex-shrink: 0;
  color: var(--base-whitealpha-900);
}

.dtl-dealer-description,
.dtl-ut-id-nunc-nec-velit-mattis-lo {
  align-self: stretch;
  position: relative;
  line-height: 32px;
  display: inline-block;
}

.dtl-ut-id-nunc-nec-velit-mattis-lo {
  font-size: var(--base-text-regular-medium-size);
  line-height: 24px;
}

.dtl-text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.dtl-iconbutton1 {
  cursor: pointer;
  border-radius: var(--br-xs);
  background-color: var(--base-red-50);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.dtl-considering-purchasing-this-20 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}

.dtl-link2 {
  cursor: pointer;
  border-radius: var(--br-xs);
  background-color: var(--color-gray-1300);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
  font-size: var(--base-text-bold-xsmall-size);
  color: var(--base-blue-600);
}

.dtl-about,
.dtl-link2,
.dtl-price-drop,
.dtl-text1 {
  display: flex;
  justify-content: flex-start;
}

.dtl-text1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-sm);
}

.dtl-about,
.dtl-price-drop {
  gap: var(--gap-lg);
}

.dtl-price-drop {
  align-self: stretch;
  border-radius: var(--br-sm);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-100);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-md);
  align-items: center;
  font-size: var(--semantic-header-navigation-size);
}

.dtl-about {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--base-heading-large-size);
}

.dtl-vehicle-specs {
  position: relative;
  line-height: 32px;
  display: inline-block;
  width: 100%;

}

.dtl-iconspeedometer {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}

.dtl-miles {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.dtl-div8,
.dtl-iconlist1 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.dtl-iconlist1 {

  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.dtl-div8 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.dtl-rectangle-div,
.dtl-rectangle-div1 {
  position: relative;
  border-radius: var(--br-xl);
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.dtl-rectangle-div {
  background-color: var(--base-black);
}

.dtl-rectangle-div1 {
  background-color: #838288;
}

.dtl-columns,
.dtl-highlights {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.dtl-columns {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--base-text-regular-medium-size);
  color: var(--base-neutral-700);
}

.dtl-highlights {
  flex-direction: column;
  align-items: flex-end;

  font-size: var(--base-heading-large-size);
}

.dtl-items,
.dtl-vehicle-features {
  position: relative;
  display: inline-block;
}

.dtl-vehicle-features {
  font-size: var(--base-heading-large-size);
  line-height: 32px;
  width: 100%;
}

.dtl-items {
  align-self: stretch;
  line-height: 24px;
}

.dtl-div13,
.dtl-list {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.dtl-div13 {
  border-radius: var(--br-2xs);
  flex-direction: column;
}

.dtl-list {
  flex-direction: row;
  gap: var(--gap-2xl);
}

.dtl-title {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.dtl-accordionicon {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}

.dtl-accordion,
.dtl-main-content,
.dtl-specialties {
  display: flex;
  justify-content: flex-start;
}

.dtl-accordion {
  border-radius: var(--br-sm);
  background-color: var(--base-white);
  flex-direction: row;
  align-items: center;
  gap: var(--gap-sm);
  color: var(--base-neutral-700);
}

.dtl-main-content,
.dtl-specialties {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.dtl-specialties {
  gap: var(--gap-lg);
}

.dtl-main-content {
  width: 100%;
  gap: 64px;
}

.dtl-b {
  flex: 1;
  position: relative;
  line-height: 40px;
  display: inline-block;
}

.dtl-goldenwrenchicon,
.dtl-iconclose,
.dtl-iconthumbs-up {
  position: relative;
  width: 14.dtl-79px;
  height: 16px;
  flex-shrink: 0;
  display: none;
}

.dtl-iconclose,
.dtl-iconthumbs-up {
  width: 16px;
  overflow: hidden;
}

.dtl-iconclose {
  width: 14px;
  height: 14px;
  opacity: 0.dtl-7;
}

.dtl-tag {
  border-radius: var(--br-2xs);
  background-color: var(--base-green-50);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  font-size: var(--base-text-regular-medium-size);
  color: var(--base-green-700);
}

.dtl-trending-down-icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.dtl-icon4 {
  border-radius: var(--br-xl);
  background-color: var(--base-green-300);
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.dtl-span {
  text-decoration: line-through;
}

.dtl-this-car-is-likely-to-sell-in {
  position: relative;
  font-size: var(--base-text-bold-xsmall-size);
  line-height: 16px;
  font-weight: 600;
  color: var(--base-neutral-600);
  text-align: left;
  display: inline-block;
}

.dtl-price-drop1,
.dtl-text2 {
  display: flex;
  justify-content: flex-start;
}

.dtl-text2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xs);
}

.dtl-price-drop1 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--base-green-50);
  border: 1px solid var(--base-green-300);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-lg) var(--padding-sm) var(--padding-md);
  align-items: center;
  gap: 12px;
  text-align: center;
  font-size: var(--semantic-header-navigation-size);
  color: var(--base-neutral-700);
}


.dtl-x-axis {
  position: absolute;
  left: 45px;
  width: 90%;
}

.dtl-x-axis {
  top: 234px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.dtl-chart-icon {
  position: absolute;
  object-fit: cover;
  left: 45px;
  width: 90%;
  top: 0;
  border-radius: 0 0 0 2px;
  max-height: 226px;
}

.dtl-k {
  position: relative;
  line-height: 16px;
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

}

.dtl-y-axis {
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-lg);
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: space-between;
}

.dtl-chart,
.dtl-price {
  align-self: stretch;
}

.dtl-chart {
  position: relative;
  height: 250px;
  width: 100%;
  flex-shrink: 0;
  text-align: right;
  font-size: var(--base-text-bold-xsmall-size);
  color: var(--base-neutral-600);
}

.dtl-price {
  display: flex;
  flex-direction: column;
  padding: var(--padding-lg);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.dtl-b1 {
  position: relative;
  font-size: var(--base-text-bold-large-size);
  line-height: 24px;
  display: none;
  color: var(--base-red-600);
}

.dtl-label1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.dtl-icon5 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.dtl-placeholder {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.dtl-input {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-sm);
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
  font-size: var(--base-text-regular-medium-size);
  color: var(--base-neutral-400);
}

.dtl-formhelpertext {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: var(--base-neutral-600);
  display: none;
}

.dtl-formcontrol,
.dtl-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.dtl-formcontrol {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-md);
}

.dtl-name {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-lg);
}

.dtl-checkbox-icon,
.dtl-customcontent {
  position: relative;
  border-radius: var(--br-xl);
  width: 16px;
  height: 24px;
  flex-shrink: 0;
}

.dtl-customcontent {
  background-color: var(--base-neutral-200);
  width: 14px;
  height: 14px;
  display: none;
}

.dtl-terms-of-use {
  text-decoration: underline;
}

.dtl-label5 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}

.dtl-checkbox,
.dtl-content1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.dtl-content1 {
  flex: 1;
  padding: 2px 0;
  box-sizing: border-box;
  align-items: center;
}

.dtl-checkbox {
  align-self: stretch;
  border-radius: var(--br-xl);
  align-items: flex-start;
  color: var(--base-neutral-800);
}

.dtl-button6,
.dtl-form {
  align-self: stretch;
  box-sizing: border-box;
}

.dtl-button6 {
  cursor: pointer;
  border-radius: var(--br-xs);
  background-color: var(--base-red-700);
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-sm);
  align-items: center;
  justify-content: center;
  font-size: var(--base-text-regular-medium-size);
  color: var(--base-white);
}

.dtl-form {
  overflow: hidden;
  padding: 0 var(--padding-lg) var(--padding-lg);
  align-items: flex-start;
  gap: var(--gap-lg);
  font-size: var(--semantic-header-navigation-size);
}

.dtl-body,
.dtl-form,
.dtl-lead-form,
.dtl-rail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dtl-lead-form {
  border-radius: var(--br-md);
  background-color: var(--base-white);
  border: 2px solid var(--base-neutral-100);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  align-items: center;
  gap: var(--gap-0);
}

.dtl-body,
.dtl-rail {
  align-items: flex-end;
  font-size: var(--base-heading-xlarge-size);
}

.dtl-body {
  width: 100%;
  flex-direction: row;
  padding: 0 var(--padding-3xl) var(--padding-2xl);
  box-sizing: border-box;
  align-items: flex-start;
  gap: var(--gap-2xl);
  font-size: var(--base-text-regular-medium-size);
  color: var(--base-black);
}

.dtl-logo-cartalk-icon1 {
  position: relative;
  width: 166.dtl-99px;
  height: 100px;
  flex-shrink: 0;
  overflow: hidden;
}

.dtl-cartalkcom-is-a-production-of {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 384px;
}

.dtl-disclaimer,
.dtl-div37 {
  display: flex;
  flex-direction: column;
}

.dtl-disclaimer {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  color: var(--base-neutral-100);
}

.dtl-div37 {
  align-self: stretch;
  border-radius: var(--br-2xs);
  padding: var(--padding-3xs) var(--padding-2xs) var(--padding-3xs) 0;
  box-sizing: border-box;
}

.dtl-div37,
.dtl-div41,
.dtl-list4,
.dtl-quick-links {
  align-items: flex-start;
  justify-content: flex-start;
}

.dtl-div41 {
  align-self: stretch;
  border-radius: var(--br-2xs);
  display: none;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-2xs) var(--padding-3xs) 0;
  box-sizing: border-box;
}

.dtl-list4,
.dtl-quick-links {
  display: flex;
}

.dtl-list4 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-2xl);
  font-size: var(--base-text-regular-medium-size);
}

.dtl-quick-links {
  width: 384px;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-lg);
  font-size: var(--base-text-bold-large-size);
}

.dtl-badge,
.dtl-popular-topics1 {
  display: flex;
  align-items: center;
}

.dtl-popular-topics1 {
  position: relative;
  font-size: var(--base-text-bold-large-size);
  line-height: 24px;
  width: 342px;
}

.dtl-badge {
  border-radius: var(--br-lg);
  background-color: var(--base-neutral-700);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  box-sizing: border-box;
  justify-content: center;
}

.dtl-content2,
.dtl-popular-topics {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.dtl-popular-topics {
  width: 384px;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-lg);
  font-size: var(--semantic-header-navigation-size);
}

.dtl-content2 {
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-3xl) 0;
  box-sizing: border-box;
  gap: var(--gap-2xl);
}

.dtl-contact-us {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.dtl-links {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.dtl-car-talk-is-a-trademark-of-ta {
  align-self: stretch;
  position: relative;
  line-height: 16px;
  font-weight: 600;
  display: inline-block;
}

.dtl-disclaimer1 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--base-text-bold-xsmall-size);
  color: var(--base-neutral-200);
}

.dtl-cl2-listing-desktop,
.dtl-disclaimer1,
.dtl-footer,
.dtl-global1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dtl-global1 {
  background-color: var(--base-black);
  width: 1440px;
  overflow: hidden;
  padding: var(--padding-xl) var(--padding-2xl);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-xl);
}

.dtl-cl2-listing-desktop,
.dtl-footer {
  align-items: flex-start;
}

.dtl-footer {
  align-self: stretch;
  background-color: var(--base-neutral-800);
  gap: 48px;
  font-size: var(--base-text-regular-medium-size);
}

.dtl-cl2-listing-desktop {
  position: relative;
  background-color: var(--base-white);
  width: 100%;
  height: 2350px;
  text-align: left;
  font-size: var(--semantic-footer-description-size);
  color: var(--base-white);
  font-family: var(--semantic-footer-description);
}


@media all and (max-width:800px) {

  .dtl-hero {
    background-color: var(--base-white);
    width: 90%;
    padding: 0;
    margin-left: 5% !important;
    padding-top: 30px;
    box-sizing: border-box;
    gap: var(--gap-lg);
    color: var(--base-blue-600);
  }

  .dtl-content {
    align-self: stretch;
    gap: var(--gap-md);
    font-size: var(--base-heading-large-size);
    color: var(--base-black);
  }

  .dtl-div4,
  .dtl-div5,
  .dtl-div6 {
    cursor: pointer;
    top: 504px;
    width: 32% !important;
    height: 256px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }

  .dtl-div13,
  .dtl-list {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .dtl-div13 {

    border-radius: var(--br-2xs);
    flex-direction: column;
    padding: 6px;
  }

  .dtl-list {
    flex-direction: row;
    gap: var(--gap-2xl);
  }

  .dtl-div8 {
    width: 100%;
    display: initial;
    justify-content: initial;
    gap: var(--gap-lg);
    flex-direction: initial;
    align-items: initial;

  }


  .dtl-columns {
    width: 100%;
    display: initial;
    justify-content: flex-start;
    gap: var(--gap-lg);
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    font-size: var(--base-text-regular-medium-size);
    color: var(--base-neutral-700);
    margin-bottom: 40px;

  }

  .dtl-list {
    display: initial;
  }


  .dtl-specialties {

    width: 100%;
    display: initial;
    justify-content: initial;
    flex-direction: initial;
    align-items: center;
    gap: var(--gap-lg);

  }

  .dtl-body {
    display: initial;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: var(--base-heading-xlarge-size);
    width: 100%;
    flex-direction: row;
    padding: 0;
    margin-left: 5% !important;

    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-2xl);
    font-size: var(--base-text-regular-medium-size);
    color: var(--base-black);
  }

  .dtl-main-content {

    margin-left: 5% !important;
    padding-right: 5% !important;
    display: initial;

  }


  .dtl-details {
    display: initial;
    justify-content: flex-start;
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-lg);
    font-size: var(--semantic-header-navigation-size);
    color: var(--base-neutral-600);
  }

  .dtl-buttons {
    flex: 1;
    margin-top: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .dtl-lead-form {
    border-radius: var(--br-md);
    background-color: var(--base-white);
    border: 2px solid var(--base-neutral-100);
    box-sizing: border-box;
    width: 90%;
    /* display: initial; */
    display: flex;
    margin: 5%;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    align-items: center !important;
    gap: var(--gap-0);
  }

  .dtl-rail {
    display: initial;

    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    font-size: var(--base-heading-xlarge-size);
  }

  .dtl-vehicle-features {
    position: relative;
    display: initial;
  }

  .dtl-vehicle-features {
    font-size: var(--base-heading-large-size);
    line-height: 32px;

    width: 100%;
  }

  .dtl-highlights {
    padding-left: 5%;
  }

  .dtl-about {

    padding: 5%;
  }

  .dtl-div13 {

    padding-left: 5%;
  }

  .dtl-accordion {
    padding-left: 5%;
  }

  .dtl-specialties {

    padding: 5%;
  }

  .dtl-button4 {


    bottom: 0px;
    border-radius: var(--br-xs);
    background-color: var(--base-black);
    display: initial;
    flex-direction: row;
    padding: var(--padding-2xs) var(--padding-sm);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }

  .dtl-iconlist1 {
    padding-top: 10px;
  }

  .dtl-div4,
  .dtl-div5,
  .dtl-div6 {
    visibility: hidden;
  }

  .dtl-div6,
  .dtl-overlay {
    padding: -10px;
  }

  .dtl-photogrid {
    margin-top: -22px;
    height: 478px;
  }

  .dtl-nav-item-vin {

    line-height: 20px;
    font-weight: 600;
    margin-top: 7.5px;
    margin-left: 20px;
  }

  .dtl-iconlist {
    display: table-row-group;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-sm);
  }

  .dtl-icon {
    float: left;
  }

  .dtl-button6 {
    position: fixed;
    width: 500px;

    top: 100%;
    left: 50%;
    margin-top: -100px;
    /* Negative half of height. */
    margin-left: -250px;
    /* Negative half of width. */
    z-index: 999;
  }
}