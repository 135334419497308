.logo-cartalk-icon {
  position: relative;
  width: 166.99px;
  height: 100px;
  flex-shrink: 0;
  overflow: hidden;
}

.cartalkcom-is-a-production-of {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 384px;
}



.disclaimer-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  color: var(--base-neutral-100);
}

.quick-links-b {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.div {
  align-self: stretch;
  border-radius: var(--br-xs);
  display: flex;
  padding: var(--padding-3xs) var(--padding-2xs) var(--padding-3xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.div,
.div4,
.list-div1 {
  flex-direction: column;
}

.div4 {
  align-self: stretch;
  border-radius: var(--br-xs);
  display: none;
  padding: var(--padding-3xs) var(--padding-2xs) var(--padding-3xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.list-div,
.list-div1,
.quick-links-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.list-div {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-2xl);
  font-size: var(--semantic-footer-description-size);
}

.quick-links-div {
  width: 384px;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-lg);
  font-size: var(--base-heading-small-size);
}

.badge-div,
.popular-topics-b {
  display: flex;
  align-items: center;
}

.popular-topics-b {
  position: relative;
  font-size: var(--base-heading-small-size);
  line-height: 24px;
  width: 342px;
}

.badge-div {
  border-radius: var(--br-lg);
  background-color: var(--base-neutral-700);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  box-sizing: border-box;
  justify-content: center;
}

.row-div {
  flex-direction: row;
  gap: var(--gap-md);
}

.content-div,
.popular-topics-div,
.row-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.popular-topics-div {
  width: 384px;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-lg);
  font-size: var(--semantic-badge-text-size);
}

.content-div {
  flex-direction: row;
  padding: var(--padding-xl) 112px 0;
  box-sizing: border-box;
  gap: var(--gap-2xl);
}

.contact-us-div {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.contact-us-div-new {
  width: 40%;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.links-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.car-talk-is-a-trademark-of-ta {
  align-self: stretch;
  position: relative;
  line-height: 16px;
  font-weight: 600;
  display: inline-block;
}

.disclaimer-div1 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--base-text-xsmall-size);
  color: var(--base-neutral-200);
}

.disclaimer-div1,
.global-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.global-div {
  background-color: var(--base-neutral-900);
  width: 100%;
  overflow: hidden;
  padding: var(--padding-xl) var(--padding-2xl);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-xl);
}

.div14 {
  cursor: pointer;
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--base-red-700);
  border: 2px solid var(--base-neutral-50);
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-md);
  align-items: center;
  justify-content: center;
  color: var(--base-neutral-50);
}

.div14,
.div16,
.intro-div,
.switchbar-div {
  display: flex;
  box-sizing: border-box;
}

.div16 {
  cursor: pointer;
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--base-neutral-50);
  border: 2px solid var(--base-neutral-50);
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-md);
  align-items: center;
  justify-content: center;
}

.intro-div,
.switchbar-div {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.switchbar-div {
  border-radius: var(--br-xl);
  background-color: var(--base-neutral-50);
  border: 1px solid var(--base-neutral-75);
  flex-direction: row;
  gap: var(--gap-0);
}

.intro-div {
  border-radius: var(--br-md) var(--br-md) 0 0;
  flex-direction: column;
  padding: var(--padding-lg) var(--padding-lg) 0;
  font-size: var(--semantic-badge-text-size);
  color: var(--base-neutral-900);
}

.iconexternal-link,
.iconlocation {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.iconexternal-link {
  display: none;
}

.link-div1 {
  cursor: pointer;
  border-radius: var(--br-sm);
  background-color: transparent;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.form-div,
.link-div,
.link-div1 {
  display: flex;
  flex-direction: row;
}

.link-div {
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
  color: var(--base-blue-600);
}

.form-div {
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
}

.iconchevron-down {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}

.select-div-new {
  width: 100%;
  float: right;
  display: flex;
  padding: 0;
  color: var(--base-neutral-900);
}

.select-div-sort {
  float: right;
  color: var(--base-neutral-900);
  width: 100% !important;
}

.sort-div>.css-42b2qy {
  margin-left: auto;
  margin-right: 0px;
  width: 350px !important;
}

.select-div {
  border-radius: var(--br-sm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  color: var(--base-neutral-900);
}

.select-div2 {
  color: var(--base-neutral-400);
}

.select-div2,
.select-div4 {
  border-radius: var(--br-sm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
}

.to-div {
  position: relative;
  line-height: 24px;
  color: var(--base-neutral-700);
  display: inline-block;

}

.basic-div,
.form-div4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-div4 {
  flex-direction: row;
  gap: var(--gap-sm);
  color: var(--base-neutral-900);
}

.basic-div {
  align-self: stretch;
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-lg);
  gap: var(--gap-xl);
}


.price-div {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.price-div {
  align-self: stretch;
  font-weight: 600;
}

.input-value-div {
  width: 20%;
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.input-value-div2 {
  width: 20%;
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.input-div,
.range-div {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  align-items: center;
}

.input-div {
  flex: 1;
  border-radius: var(--br-sm);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-200);
  box-sizing: border-box;
  padding: var(--padding-2xs) var(--padding-sm);
  justify-content: center;
  gap: var(--gap-sm);
}

.range-div {
  width: 274px;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.rangesliderfilledtrack-div,
.rangeslidertrack-div {
  position: absolute;
  width: 100%;
  top: 10px;
  right: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--base-neutral-100);
  height: 4px;
}

.rangesliderfilledtrack-div {
  background-color: var(--base-red-500);
}

.rangesliderthumb-div,
.rangesliderthumb-div1 {
  position: absolute;
  top: 0;
  border-radius: var(--br-xl);
  background-color: var(--base-white);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--base-red-400);
  box-sizing: border-box;
  width: 24px;
  height: 24px;
}

.rangesliderthumb-div {
  left: 0;
}

.rangesliderthumb-div1 {
  right: 0;
}

.range-slider-div {
  position: relative;
  width: 100%;
  height: 24px;
  flex-shrink: 0;
}

.content-div2 {
  padding: var(--padding-2xs) 0 0;
  box-sizing: border-box;
  color: var(--base-neutral-900);
}

.content-div2,
.content-div3,
.filter-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.Clear-Tools {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.clearButton {
  cursor: pointer;
  border-radius: var(--br-sm);
  background-color: var(--base-red-50);
  padding: var(--padding-sm);
  align-items: left;
  color: var(--base-red-700);
}

.filter-div {
  align-self: stretch;
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  box-sizing: border-box;
  padding: var(--padding-lg);
}

.content-div3 {
  color: var(--base-neutral-900);
}

.title-div {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.accordionicon {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}

.accordion-div {
  align-self: stretch;
  border-radius: var(--br-md);
  background-color: var(--base-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.filter-div-acordion {
  align-self: stretch;
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  width: 100%;

}

.filter-div14,
.filter-div2,
.filters-div {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.filter-div14 {
  align-self: stretch;
  padding: var(--padding-lg);
}

.filters-div {
  border-radius: var(--br-md);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-100);
  width: 34, 6%;
  flex-shrink: 0;
  gap: var(--gap-0);
}

.label-div,
.results-div1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.results-div1 {
  width: 50%;
  flex-shrink: 0;
}

.label-div {
  font-weight: 600;
}

.sort-box {
  padding: 0px !important;
}

.sort-div {
  float: revert;
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: flex-start;
  gap: var(--gap-md);
  text-align: right;
}

.sort-div1 {
  text-align: right;
  width: 80% !important;
  white-space: nowrap;
}

.tools-div {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.tools-div {
  align-self: stretch;
  justify-content: flex-end;
  gap: var(--gap-xl);
}

.miles-div {
  margin-left: 1%;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 23%;
  flex-shrink: 0;
}

.price-div1 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 20%;
  margin-left: 1%;
  flex-shrink: 0;
}

.remove-div {
  cursor: pointer;
  text-align: right;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 23%;
  flex-shrink: 0;
  
  margin-left: auto!important;
  padding-right: 10px!important;
}

.year-make-model {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 18.5%;
  margin-left: 1%;
  flex-shrink: 0;
}

.photo-div {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  margin-left: 1%;
  display: inline-block;
  /* width: 7%; */
  flex-shrink: 0;
}

.save-div {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 3.5%;
  flex-shrink: 0;
}






.header-div,
.savebutton-div {
  width: 35px;
  height: 35px;
  border-radius: var(--br-sm);
  background-color: var(--base-blackalpha-700);
  padding: var(--padding-2xs);
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
}

.header-div {
  width: 100%;
  align-self: stretch;
  background-color: var(--base-neutral-75);
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  padding: var(--padding-2xs) var(--padding-md);
  align-items: center;
  gap: var(--gap-0);
}



.image-icon {
  position: relative;
  border-radius: var(--br-xs);
  width: 100px;
  height: auto;
/*height: 80px;*/
  flex-shrink: 0;
  /* object-fit: cover; */
}

.richmond-va-64-miles-away {
  align-self: stretch;
  position: relative;
  font-size: var(--semantic-badge-text-size);
  line-height: 20px;
  color: var(--base-neutral-600);
  display: inline-block;
}

.content-div5 {
  width: 18%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.listrow-div {
  align-self: stretch;
  display: none;
  justify-content: flex-start;
}

.listrow-div,
.listrow-div1,
.listrow-div2 {
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-md);
  align-items: center;
  gap: var(--gap-xl);
}

.listrow-div1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.listrow-div2 {
  background-color: var(--base-neutral-50);
}

.content-div4,
.listrow-div2,
.listrow-div30 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.listrow-div30 {
  background-color: var(--base-neutral-50);
  flex-direction: row;
  padding: var(--padding-md);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-xl);
}

.content-div4,


.content-div4 {
  font-size: var(--base-heading-small-size);
}

.list-div3 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-0);
  border-radius: var(--br-md);
  border: 0px solid var(--base-neutral-100);
  box-sizing: border-box;
  overflow: hidden;
  color: var(--base-neutral-900);
}

.button-div,
.iconbutton-div {
  border-radius: var(--br-sm);
  box-sizing: border-box;
  align-items: center;
}

.iconbutton-div {
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  justify-content: center;
}

.button-div {
  background-color: var(--base-blue-100);
  width: 40px;
  flex-shrink: 0;
  padding: var(--padding-2xs) var(--padding-sm);
}

.button-div,
.button-div1,
.pagination-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
}

.button-div1 {
  border-radius: var(--br-sm);
  width: 40px;
  flex-shrink: 0;
  padding: var(--padding-2xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
}

.pagination-div {
  align-items: flex-start;
  gap: var(--gap-xs);
  z-index: 0;
}

.icon4 {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.tools-div1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button-div5 {
  padding-top: 10 !important;
  display: flex;
  border-radius: var(--br-sm);
  background-color: var(--base-red-50);
  padding: var(--padding-2xs) var(--padding-sm);
  align-items: center;
  color: var(--base-red-700);
}

.tools-div1 {
  align-self: stretch;
  align-items: flex-start;
  position: relative;
  gap: var(--gap-xl);
  color: var(--base-neutral-700);
}

.content-div1,
.uults-div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.results-div {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
  color: var(--base-neutral-600);
}

.content-div1 {
  position: relative;
  margin-top: 100px;
  top: 180px;
  left: 0;
  width: 100%;
  flex-direction: row;
  padding: 0 var(--padding-lg) var(--padding-2xl);
  box-sizing: border-box;
  gap: var(--gap-2xl);
  color: var(--base-neutral-700);
}

.cars-best-minvans {
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.breadcrumbs-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.used-car-listings-in-richmond {
  position: relative;
  font-size: var(--base-heading-xlarge-size);
  line-height: 40px;
  display: inline-block;
  color: var(--base-neutral-900);
}

.content-div36 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.radiobox-div {
  border-radius: var(--br-md);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-100);
  box-sizing: border-box;
  padding: var(--padding-2xs) var(--padding-md);
  align-items: center;
}

.radiobox-div,
.radiobox-div1,
.radioboxrow-div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-md);
}

.radiobox-div1 {
  cursor: pointer;
  border-radius: var(--br-md);
  background-color: var(--base-blue-50);
  border: 2px solid var(--base-blue-500);
  box-sizing: border-box;
  padding: var(--padding-2xs) var(--padding-md);
  align-items: center;
  color: var(--base-blue-800);
}

.radioboxrow-div {
  align-items: center;
  color: var(--base-neutral-900);
}

.hero-div,
.iconbutton-div2 {
  position: absolute;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.hero-div {
  top: 56px;
  left: 0;
  width: 100%;
  padding: var(--padding-md) var(--padding-lg) var(--padding-xl);
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-lg);
  font-size: var(--semantic-badge-text-size);
  color: var(--base-neutral-700);
}

.iconbutton-div2 {
  top: 8px;
  right: 12px;
  border-radius: var(--br-sm);
  background-color: var(--base-red-700);
  padding: var(--padding-xs);
  align-items: center;
  justify-content: center;
}

.cl1-search-list-desktop {
  background-color: var(--base-white);
  width: 100%;

  font-size: var(--semantic-footer-description-size);
  color: var(--base-white);
  font-family: var(--semantic-footer-description);
}
.location-div{
  display: flex;
}

@media screen and (max-width:1100px) {
  .filterDeskButton{
    visibility: hidden;
   }
.content-div2{
  width: 100%!important;
}
.content-div3{
  width: 100%!important;
}
  .location-div{
    display: none;
  }
  .cl1-search-list-desktop {
    background-color: var(--base-white);
    width: 100%;
    font-size: var(--semantic-footer-description-size);
    color: var(--base-white);
    font-family: var(--semantic-footer-description);
    height: auto !important;
  }

  .content-div1 {
    position: relative;

    left: 0;
    width: 100%;
    flex-direction: row;
    padding: 0 var(--padding-lg) var(--padding-2xl);
    box-sizing: border-box;
    gap: var(--gap-2xl);
    color: var(--base-neutral-700);
    display: block !important;
    margin-top: 180px;
    height: auto !important;
  }

  .filters-div {
    border-radius: var(--br-md);
    background-color: var(--base-white);
    border: 1px solid var(--base-neutral-100);
    width: 100%;
    gap: var(--gap-0);
    grid-row-end: auto;
  }


  .results-div1 {
    width: 30%;
    flex-shrink: 0;
  }

  .hero-div {
    top: 56px;
    left: 0;
    width: 100%;
    padding: var(--padding-md) var(--padding-lg) var(--padding-xl);
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-lg);
    font-size: var(--semantic-badge-text-size);
    color: var(--base-neutral-700);
    display: table-column !important;
    padding-bottom: 0px !important;
  }

  .content-div36 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lg);
    width: 100%;
  }

  .radioboxrow-div {
    align-items: center;
    color: var(--base-neutral-900);
    width: 100%;
    ;
    margin-top: 2%;
  }



  .button-div5 {
    padding-top: 10 !important;
    display: flex;

    width: 150px;
    border-radius: var(--br-sm);
    background-color: var(--base-red-50);
    padding: var(--padding-2xs) var(--padding-sm);
    margin-left: 45%;
    align-items: center;
    color: var(--base-red-700);
  }

  .tools-div1 {
    zoom: 60%;
  }
}


@media only screen and (max-width: 1020px) and (min-width: 800px)  {
  .image-icon {

    width: 100px!important;
    height: auto!important;

  }
  .tools-div1 {
    zoom: 70%;
  }
  .input-value-div,
  .content-div5 {
  
    font-size: 10px;
  }
  .savebutton-div {
    width: 35px!important;
    height: 35px!important;

  }
  .iconlocation {
    position: relative;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .miles-div {
    font-size: 10px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 20%;
    flex-shrink: 0;
  }

  .price-div1 {
    font-size: 10px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width:10%;
    flex-shrink: 0;
  }

  .remove-div {
    font-size: 10px;
    cursor: pointer;
    text-align: right;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 15%;
    flex-shrink: 0;
  }

  .year-make-model {
    font-size: 10px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 30%;
    flex-shrink: 0;
  }

  .photo-div {
    font-size: 10px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 90px!important;
    flex-shrink: 0;
  }

  .save-div {
    font-size: 10px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    /* width: 10%; */
    width: 35px;
    flex-shrink: 0;
  }

}
@media only screen and (max-width: 1350px) and (min-width: 1020px) 
 {
  .miles-div {

    width: 19%;

  }

  .price-div1 {

    width: 15%;

  }

  .remove-div {

    width: 18%;
  }

  .year-make-model {

    width: 20%;

  }

  .photo-div {

    width: 115px;

  }

  .save-div {

    width: 50px;

  }
}
@media only screen and (max-width: 1600px) and (min-width: 1350px) 
 {
  .miles-div {

    width: 18%;

  }

  .price-div1 {

    width: 20%;

  }

  .remove-div {

    width: 18%;

  }

  .year-make-model {

    width: 21%;

  }

  .photo-div {

    width: 10%;

  }

  .save-div {

    width: 8%;

  }
  
  .richmond-va-64-miles-away {
    font-size: x-small!important;
  }
}
