@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --semantic-footer-description: "Open Sans";

  /* font sizes */
  --base-text-xsmall-size: 12px;
  --semantic-badge-text-size: 14px;
  --semantic-footer-description-size: 16px;
  --base-heading-small-size: 18px;
  --base-heading-xlarge-size: 32px; 
  --semantic-header-navigation-size: 14px; 
  --base-text-regular-medium-size: 16px; 
  --base-text-bold-large-size: 18px;
  --base-text-bold-xlarge-size: 24px;
  --base-heading-large-size: 24px;

  
  --base-heading-xlarge-size: 32px;
  --base-heading-2xlarge-size: 40px;

  /*s Colors */
  --base-white: #fff;
  --base-red-400: #f6657d;
  --base-red-500: #f33a59;
  --base-red-700: #c3161c;
  --base-red-50: #fff3f5;
  --base-neutral-50: #f7f7f7;
  --base-blue-50: #e7f5fe;
  --base-neutral-75: #eee;
  --base-neutral-100: #dbdbdb;
  --base-blue-100: #bbe2fc;
  --base-neutral-200: #c4c4c4;
  --base-neutral-400: #969696;
  --base-blue-500: #0c96f3;
  --base-neutral-600: #666;
  --base-blue-600: #0978c3;
  --base-blue-800: #075a92;
  --base-neutral-700: #444;
  --base-neutral-800: #282828;
  --base-neutral-900: #1a1a1a;
  --base-blackalpha-700: rgba(26, 26, 26, 0.7);
  --base-green-50: #f0fff4;
  --base-black: #1a1a1a;
  --base-blackalpha-400: rgba(26, 26, 26, 0.24);
  --base-whitealpha-900: rgba(255, 255, 255, 0.9);
  --color-gray-1300: rgba(255, 255, 255, 0);
  --base-red-600: #f00b31;
  --base-green-300: #68d391;
  --base-green-700: #276749;

  /* Gaps */
  --gap-0: 0px;
  --gap-xs: 2px;
  --gap-sm: 4px;
  --gap-md: 8px;
  --gap-lg: 16px;
  --gap-xl: 24px;
  --gap-2xl: 32px;

  /* Paddings */
  --padding-3xs: 4px;
  --padding-2xs: 8px;
  --padding-xs: 10px;
  --padding-sm: 12px;
  --padding-md: 16px;
  --padding-lg: 24px;
  --padding-xl: 32px;
  --padding-2xl: 64px;
  --padding-3xl: 112px;

  /* border radiuses */
  --br-xs: 4px;
  --br-sm: 6px;
  --br-md: 8px;
  --br-lg: 40px;
  --br-xl: 50px;
}
