.iconCarGrd {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.iconButton {
  position: absolute;
  top: 8px;
  right: 12px;
  border-radius: var(--br-sm);
  background-color: var(--base-red-700);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.logoCarTalkIcon {
  position: absolute;
  top: 20px;
  left: 24px;
  width: 95px;
  height: 24px;
}

.headerNavBar,
.navItem {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navItem {
  border-radius: var(--br-xs);
  padding: var(--padding-2xs);
  box-sizing: border-box;
  justify-content: center;
}

.headerNavBar {
  position: absolute;
  top: 14px;
  left: calc(50% - 577px);
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.header {
  position: relative;
  background-color: var(--base-red-700);
  width: 1440px;
  height: 56px;
  flex-shrink: 0;
  text-align: center;
}

.carsBestMinvans {
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.usedCarListingsInRichmond {
  position: relative;
  font-size: var(--base-heading-xlarge-size);
  line-height: 40px;
  display: inline-block;
  color: var(--base-black);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.iconchart {
  position: relative;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  overflow: hidden;
}

.bestDeal {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}

.radioBox {
  border-radius: var(--br-md);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-100);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-lg);
  align-items: center;
}

.radioBox,
.radioBox2,
.radioBoxRow {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.radioBox2 {
  border-radius: var(--br-md);
  background-color: var(--base-blue-50);
  border: 2px solid var(--base-blue-500);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-lg);
  align-items: center;
  color: var(--base-blue-800);
}

.radioBoxRow {
  align-items: flex-start;
  color: var(--base-black);
}

.div,
.hero,
.radioBoxRow {
  flex-direction: row;
}

.hero {
  width: 1440px;
  display: flex;
  padding: var(--padding-lg) var(--padding-xl) var(--padding-2xl);
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-lg);
  color: var(--base-neutral-700);
}

.div {
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--base-red-700);
  /* border: 2px solid var(--base-neutral-50); */
  padding: var(--padding-xs) var(--padding-lg);
  align-items: center;
  justify-content: center;
  color: var(--base-neutral-50);
}

.div,
.div2,
.intro,
.switchBar {
  display: flex;

}

.div2 {
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--base-neutral-50);
  /* border: 2px solid var(--base-neutral-50); */
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-lg);
  align-items: center;
  justify-content: center;
}

.intro,
.switchBar {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.switchBar {
  border-radius: var(--br-xl);
  background-color: var(--base-neutral-50);
  border: 1px solid var(--base-neutral-75);
  flex-direction: row;
  gap: var(--gap-0);
}

.intro {
  border-radius: var(--br-md) var(--br-md) 0 0;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-xl) 0;
  font-size: var(--semantic-header-navigation-size);
  color: var(--base-black);
}

.location {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.iconexternalLink {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.link1 {
  border-radius: var(--br-sm);
  background-color: transparent;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.form,
.link,
.link1 {
  display: flex;
  flex-direction: row;
}

.link {
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
  color: var(--base-blue-600);
}

.form {
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
}

.iconchevronDown {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}

.select {
  border-radius: var(--br-sm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  color: var(--base-black);
}

.select2 {
  color: var(--base-neutral-400);
}

.select2,
.select4 {
  border-radius: var(--br-sm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
}

.to {
  position: relative;
  line-height: 24px;
  color: var(--base-neutral-700);
  display: inline-block;
}

.basic,
.form4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.form4 {
  flex-direction: row;
  gap: var(--gap-sm);
  color: var(--base-black);
}

.basic {
  align-self: stretch;
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-xl);
}

.inputValue,
.price {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.price {
  align-self: stretch;
  font-weight: 600;
}

.inputValue {
  flex: 1;
}

.input,
.range {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input {
  flex: 1;
  border-radius: var(--br-sm);
  background-color: var(--base-white);
  border: 1px solid var(--base-neutral-200);
  box-sizing: border-box;
  padding: var(--padding-xs) var(--padding-md);
  justify-content: center;
  gap: var(--gap-sm);
}

.range {
  width: 274px;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.rangeSliderFilledTrack,
.rangeSliderTrack {
  position: absolute;
  width: 100%;
  top: 10px;
  right: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--base-neutral-100);
  height: 4px;
}

.rangeSliderFilledTrack {
  background-color: var(--base-red-500);
}

.rangeSliderThumb,
.rangeSliderThumb1 {
  position: absolute;
  top: 0;
  border-radius: var(--br-xl);
  background-color: var(--base-white);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--base-red-400);
  box-sizing: border-box;
  width: 24px;
  height: 24px;
}

.rangeSliderThumb {
  left: 0;
}

.rangeSliderThumb1 {
  right: 0;
}

.rangeSlider {
  position: relative;
  width: 274px;
  height: 24px;
  flex-shrink: 0;
}

.content2 {
  padding: var(--padding-xs) 0 0;
  /* box-sizing: border-box; */
  color: var(--base-black);
}

.content2,
.content3,
.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.filter {
  align-self: stretch;
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  /* box-sizing: border-box; */
  padding: var(--padding-xl);
}

.content3 {
  color: var(--base-black);
}

.title {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.accordion {
  align-self: stretch;
  border-radius: var(--br-md);
  background-color: var(--base-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}

.filter2 {
  align-self: stretch;
  border-top: 0 solid var(--base-neutral-100);
  border-right: 0 solid var(--base-neutral-100);
  border-bottom: 1px solid var(--base-neutral-100);
  border-left: 0 solid var(--base-neutral-100);
  padding: var(--padding-xl);
}

.filter14,
.filter2,
.filters {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.filter14 {
  align-self: stretch;
  padding: var(--padding-xl);
}

.filters {
  border-radius: var(--br-md);
  background-color: var(--base-white);
  /* border: 1px solid var(--base-neutral-100); */
  width: 324px;
  flex-shrink: 0;
  gap: var(--gap-0);
}

.label,
.results1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.results1 {
  width: 833px;
  flex-shrink: 0;
}

.label {
  font-weight: 600;
}

.sort,
.tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.tools {
  align-self: stretch;
  justify-content: flex-end;
  gap: var(--gap-xl);
}

.imageIcon {
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 0;
  right: -0.33px;
  left: 0;
  border-radius: var(--br-md) var(--br-md) 0 0;

  overflow: hidden;
  height: 165px;
  object-fit: cover;
}

.removeButton,
.saveButton {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 7.67px;
  border-radius: var(--br-sm);
  background-color: var(--base-blackalpha-700);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.removeButton {
  top: 48px;
}

.image {
  align-self: stretch;
  position: relative;
  height: 165px;
  flex-shrink: 0;
}

.miles,
.yearMakeModelTrim {
  position: relative;
  display: inline-block;
}

.yearMakeModelTrim {
  align-self: stretch;
  line-height: 24px;
}

.miles {
  flex: 1;
  line-height: 20px;
}

.dataItem {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.milesAway,
.richmondVA {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.richmondVA {
  font-size: var(--base-text-regular-xsmall-size);
  line-height: 16px;
  color: var(--base-neutral-600);
}

.content5,
.dataItem1,
.dataList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.content5 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-0);
}

.dataItem1,
.dataList {
  gap: var(--gap-md);
}

.dataItem1 {
  align-self: stretch;
  flex-direction: row;
}

.dataList {
  flex: 1;
  flex-direction: column;
}

.goldenWrenchIcon {
  position: relative;
  width: 11.09px;
  height: 12px;
  flex-shrink: 0;
  display: none;
}

.icon5,
.label1 {
  position: relative;
  font-size: var(--base-text-xsmall-size) !important;
}

.icon5 {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  overflow: hidden;
}

.label1 {
  line-height: 16px;
  font-weight: 600;
  font-size: var(--base-text-xsmall-size) !important;
  display: inline-block;
}

.iconclose1 {
  position: relative;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
  opacity: 0.7;
}

.tag {
  border-radius: var(--br-xs);
  background-color: var(--base-green-50);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}

.b {
  position: relative;
  font-size: var(--base-text-bold-xlarge-size) !important;
  line-height: 32px;
  display: inline-block;
  color: var(--base-black);
}

.price1 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-sm);
  font-size: var(--base-text-bold-xlarge-size) !important;
  color: var(--base-green-700);
}

.content4,
.main,

.searchCardListing {
  display: flex;
  justify-content: flex-start;
}

.main {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-md);
  font-size: var(--semantic-header-navigation-size);
}

.content4,
.searchCardListing {
  flex-direction: column;
  align-items: flex-start;
}

.content4 {
  cursor: pointer;
  align-self: stretch;
  padding: var(--padding-xl) var(--padding-lg);
  box-sizing: border-box;
  gap: var(--gap-lg);
}

.searchCardListing {
  flex: 1;
  height: 320px;
  border-radius: var(--br-md);
  background-color: var(--base-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  gap: 16px;
  /* box-shadow: var(--base-defaultshadow); */
  gap: var(--gap-0);

}

.tag1 {
  border-radius: var(--br-xs);
  background-color: var(--base-green-50);
  display: none;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}

.div6,
.grid1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.grid1 {
  flex-direction: column;
  font-size: var(--base-heading-small-size);
  color: var(--base-black);
  display: flex;
}

.grid3 {
  width: 100% !important;
  display: block;
}

.div8 {
  gap: var(--gap-lg);
  margin-bottom: 1rem;
  padding-left: 2px !important;
  padding-right: 2px !important;
  width: 32% !important;
  float: left;
  border: 0px solid var(--base-neutral-100);
  justify-content: space-between;
}
.div8-2 {
  border: 0px solid var(--base-neutral-100);
  gap: var(--gap-lg);
  margin-bottom: 1rem;
  width: 48% !important;
  float: left;
  justify-content: space-between;
}
.div8-margin {
  margin-left: 2% !important;
}

.button,
.iconButton1 {
  border-radius: var(--br-sm);
  box-sizing: border-box;
  align-items: center;
}

.iconButton1 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  justify-content: center;
}

.button {
  background-color: var(--base-blue-100);
  width: 40px;
  flex-shrink: 0;
  padding: var(--padding-xs) var(--padding-md);
}

.button,
.button1,
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button1 {
  border-radius: var(--br-sm);
  width: 40px;
  flex-shrink: 0;
  padding: var(--padding-xs) var(--padding-md);
  box-sizing: border-box;
  align-items: center;
}

.pagination {
  align-items: flex-start;
  gap: var(--gap-xs);
  z-index: 0;
}

.icon35 {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.button5 {
  margin: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--br-sm);
  background-color: var(--base-red-50);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-md);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  z-index: 1;
  color: var(--base-red-700);
}

.content1,
.results,
.tools1 {
  display: flex;
  align-items: flex-start;
}

.tools1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  position: relative;
  gap: var(--gap-xl);
  color: var(--base-neutral-700);
}

.content1,
.results {
  justify-content: flex-start;
}

.results {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
  color: var(--base-neutral-600);
}

.content1 {
  width: 1440px;
  flex-direction: row;
  padding: 0 var(--padding-xl) var(--padding-3xl);
  /* box-sizing: border-box; */
  gap: var(--gap-2xl);
  font-size: var(--base-text-regular-medium-size);
  color: var(--base-neutral-700);
}

.logoCarTalkIcon1 {
  position: relative;
  width: 166.99px;
  height: 100px;
  flex-shrink: 0;
  overflow: hidden;
}

.cartalkcomIsAProductionOf {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 384px;
}

.disclaimer,
.div23 {
  display: flex;
  flex-direction: column;
}

.disclaimer {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  color: var(--base-neutral-100);
}

.div23 {
  border-radius: var(--br-xs);
  padding: var(--padding-2xs) var(--padding-xs) var(--padding-2xs) 0;
  /* box-sizing: border-box; */
}

.div23,
.div27,
.list1 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.div27 {
  border-radius: var(--br-xs);
  display: none;
  flex-direction: column;
  padding: var(--padding-2xs) var(--padding-xs) var(--padding-2xs) 0;
  /* box-sizing: border-box; */
}

.list1 {
  display: flex;
  flex-direction: row;
  gap: var(--gap-2xl);
  font-size: var(--base-text-regular-medium-size);
}


.imageIcon-sold {
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 0;
  right: -0.33px;
  left: 0;
  border-radius: var(--br-md) var(--br-md) 0 0;

  overflow: hidden;
  height: 165px;
  object-fit: cover;
  opacity: 0.4;
}
.soldG-txt {
  position: absolute;
  top: 50px;
  left: 100px;
  color: red;
  transform: rotate(45deg);

  font-size: 60px;
}
.soldG-container {
  position: relative;
  text-align: center;
  color: white;
}

.popularTopics1,
.quickLinks {
  font-size: var(--base-heading-small-size);
  display: flex;
}

.quickLinks {
  width: 384px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}

.popularTopics1 {
  position: relative;
  line-height: 24px;
  align-items: center;
  width: 342px;
}

.badge,
.row {
  flex-direction: row;
}

.badge {
  border-radius: var(--br-lg);
  background-color: var(--base-neutral-700);
  display: flex;
  padding: var(--padding-2xs) var(--padding-md);
  /* box-sizing: border-box; */
  align-items: center;
  justify-content: center;
}

.row {
  align-items: flex-start;
  gap: var(--gap-md);
}

.content64,
.links,
.popularTopics,
.row {
  display: flex;
  justify-content: flex-start;
}

.popularTopics {
  width: 384px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-lg);
  font-size: var(--semantic-header-navigation-size);
}

.content64,
.links {
  flex-direction: row;
}

.content64 {
  padding: var(--padding-2xl) 112px 0;
  /* box-sizing: border-box; */
  align-items: flex-start;
  gap: var(--gap-2xl);
}

.links {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-xl);
}

.cARTALKIsATrademarkOfTa {
  align-self: stretch;
  position: relative;
  line-height: 16px;
  font-weight: 600;
  display: inline-block;
}

.disclaimer1 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--base-text-regular-xsmall-size);
  color: var(--base-neutral-200);
}

.searchGrid,
.disclaimer1,
.footer,
.global {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.global {
  background-color: var(--base-black);
  width: 1440px;
  overflow: hidden;
  padding: var(--padding-2xl) var(--padding-3xl);
  /* box-sizing: border-box; */
  align-items: center;
  gap: var(--gap-xl);
}

.searchGrid,
.footer {
  align-items: flex-start;
}

.footer {
  background-color: var(--base-neutral-800);
  width: 1440px;
  gap: 48px;
  font-size: var(--base-text-regular-medium-size);
}

.searchGrid {
  position: relative;
  background-color: var(--base-white);
  width: 100%;
  gap: var(--gap-0);
  text-align: left;
  font-size: var(--semantic-header-navigation-size);
  color: var(--base-white);
  font-family: var(--semantic-header-navigation);
}

.grid2 {
  display: none;
}

@media screen and (max-width:800px) {
  .grid1 {
    display: none;
  }

  .grid3 {
    display: none !important;
  }

  .grid2 {
    width: 100%;
    height: auto;
    border: none;
    color: var(--base-black);
    font-size: var(--base-heading-small-size);
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    display: block !important;
    gap: var(--gap-lg);
  }

  .div7 {

    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lg);
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .searchCardListing {

    width: 100%;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

}
.graph{
  width: 100% !important;
  height: 400;
}
.h1 {
  font-size: 2rem;
}

@media screen and (max-width:800px) {
  .h1 {
    display: none;
  }
  .graph{
    display: none;
    visibility: hidden;
  }
}

/* @media screen and (max-width:1260px)  {
  .searchCardListing {
    height: 420px;
    width: 900px;
  }
} */
/* 
@media screen and (min-width:1261px)  {
  .searchCardListing {
    height: 350px;
    width: 700px;
  }
} */
