.hdr-iconbutton-div2 {
  top: 8px;
  right: 12px;
  border-radius: var(--br-sm);
  background-color: var(--base-red-700);
  padding: var(--padding-xs);
  align-items: center;
  justify-content: center;
  display: none;
}
.hdr-logo-cartalk-icon1 {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 24px;
  width: 95px;
  height: 24px;
}

.hdr-navitem-div {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hdr-best-deal-div {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}

.hdr-navitem-div {
  border-radius: var(--br-xs);
  padding: var(--padding-3xs);
  box-sizing: border-box;
  justify-content: center;
}
.hdr-headernavbar-div {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 14px;
  left: 20%;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.hdr-header-div1 {
  position: static;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--base-red-700);
  color: white;
  height: 60px;
  text-align: center;
  font-size: var(--semantic-badge-text-size);
}

.header-div-menu{
  float: right!important;
  margin: 0.5rem;
}
@media screen and (max-width:800px){
  .hdr-headernavbar-div{
    display: none;
  }
  .header-div-menu{
    visibility: hidden;
  }
  .hdr-iconbutton-div2 {
    display: block;
    top: 8px;
    right: 12px;
    border-radius: var(--br-sm);
    background-color: var(--base-red-700);
    float: right;
    padding: var(--padding-xs);
    align-items: center;
    justify-content: center;
  }
  .hdr-logo-cartalk-icon1 {
    position: absolute;
    top: 20px;
    right: inherit;
    width: 95px;
    height: 24px;
  }
  .menu-button {
    background-color: blue;
  }
}
/* #hdr-menu{
  display: none;
} */